import React, { useEffect, useState } from 'react'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import './home.css'
import { Link } from 'react-router-dom'
import MenuPhone from './menuPhone'

import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
// or
import '@splidejs/splide/dist/css/themes/splide-sea-green.min.css';
// or
import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css';
gsap.registerPlugin(ScrollTrigger)
export default function Home() {
    const [margin, setMargin] = useState(0)
    const [menu, setMenu] = useState(0)
    const [color, setColor] = useState('#fff')
    const [clientes, setClientes] = useState([1, 2, 3, 4, 5, 6, 7])
    
    useEffect(async () => {
        window.addEventListener('scroll', () => {
            setMargin(window.scrollY / window.innerHeight * 50)
        })
        gsap.to('.phoneCaracter', {
            scrollTrigger: {
                trigger: '.elemental',  // this will use the first box as the trigger
                scrub: true,
                pin: true
            },
            marginBottom: 0,
            stagger: 0.1,
            opacity: 1,
        })
        gsap.to('.labels', {
            scrollTrigger: {
                trigger: '.elemental',  // this will use the first box as the trigger
                scrub: true,
                pin: true,
            },
            marginBottom: 0,
            opacity: 1,
            stagger: 0.1,
            ease: 'back',
        })
        gsap.to('.logoCarga', {
            scrollTrigger: {
                trigger: '.logoCentrado',  // this will use the first box as the trigger
                scrub: true,
                pin: true,
            },
            opacity: 1,
        })
        gsap.to('.deluxePhone', {
            scrollTrigger: {
                trigger: '.deluxe',  // this will use the first box as the trigger
                scrub: true,
                pin: true,
            },
            marginBottom: 0,
            stagger: 0.5,
            opacity: 1,

        })
        gsap.to('.ecomercePhone', {
            scrollTrigger: {
                trigger: '.ecomerce',  // this will use the first box as the trigger
                scrub: true,
                pin: true,
            },
            stagger: 0.5,
            opacity: 1,

        })

    }, [])
    const scrollTo = (elemento) => {
        document.getElementById(elemento).scrollIntoView({
            block: "end",
            behavior: 'smooth'
        })
    }
    const greenClientes = (url) => {
        window.open(url);
    }
    const comienzaAhora = (tarjeta) => {
        const texto = `Hola quiero comenzar con mi Greencardpv ${tarjeta}`
        window.location.href = `https://api.whatsapp.com/send?phone=523223702372&text=${texto.replace(/ /g, '%20')}`
    }
    const contactar = (numero) => {
        const texto = `Hola quiero comenzar con mi Greencardpv`
        window.location.href = `https://api.whatsapp.com/send?phone=${numero}&text=${texto.replace(/ /g, '%20')}`
    }
    
    if (window.matchMedia("(max-width: 768px)").matches) {
        return (
            <div className="bodyPhone">
                <div className="MenuPhone">
                    <div className="logoSection"><img src="./images/greencard.png" className="logoMobile" alt="" /></div>
                    <div onClick={() => setMenu(3)}><i class="fas fa-bars"></i></div>
                </div>
                <MenuPhone menu={menu} Set={setMenu} />
                <div className="section" id="heroHome">
                    <div className="font" style={{ marginBottom: '2em' }}>Crea una experiencia única con tus clientes donde puedan obtener toda tu información, dando opción de cómo quieren conectarse contigo o tu negocio</div>
                    <div className="phoneMobileHero"><img src="./images/phoneMobile.png" alt="" /></div>
                </div>
                <div className="section" style={{ marginTop: '2em' }} id="  ">
                    <div className="textCaracteristica">
                        <div className="title">Elemental</div>
                        <div className="subtitle" style={{ maxWidth: '80%' }}>Una Tarjeta digital diseñada por y para ti.</div>
                        <p style={{ maxWidth: '300px' }}>
                            Causa una buena primera impresión con Greencardpv ELEMENTAL tu tarjeta de presentación digital y sólo en segundos podrán acceder a tus datos de contacto sin descargar ningún APP.
                        </p>
                    </div>
                    <div className="elementalPhone">
                        <div className="phoneElementalMobil">
                            <img className="phoneCaracterMobil" src="./images/phonecaracter.png" alt="" />
                        </div>
                        <div className="textElementalPhone">
                            <div>Una tarjeta NFC con QR digital</div>
                            <div>Integra todas tus Redes Sociales</div>
                            <div>Tu propio Subdominio</div>
                            <div>Diseño Personalizado de tu Greencardpv Digital</div>
                        </div>
                    </div>
                    <div onClick={() => contactar('523223702372')} className="descuentoMobil">
                        <div className="containerMobil">
                            OBTEN UN 50% DE DESCUENTO, COMIENZA AHORA
                        </div>
                    </div>
                </div>
                <div className="section" style={{ marginTop: '2em' }} id="Deluxe">
                    <div className="textCaracteristica">
                        <div className="title">Deluxe</div>
                        <div className="subtitle" style={{ maxWidth: '80%' }}>Tu Catálogo o brochure de productos o servicios</div>
                        <p style={{ maxWidth: '300px' }}>
                            Una nueva manera de presentar tu Catálogo a tus clientes en segundos.
                            podrán acceder a tus datos de contacto y servicios
                            desde su teléfono sin descargar una APP.
                        </p>
                    </div>
                    <div className="deluxePhoneMobil">
                        <div className="phoneElementalMobil" style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
                            <div className="slider">
                                <Splide options={{
                                    type: 'loop',
                                    rewind: true,
                                }}>
                                    <SplideSlide >
                                        <img src={`./images/deluxe1.png`} alt="" className="imgSlider" />
                                    </SplideSlide>
                                    <SplideSlide>
                                        <img src={`./images/deluxe2.png`} alt="" className="imgSlider" />
                                    </SplideSlide>
                                </Splide>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section" style={{ marginTop: '2em' }} id="Ecommerce">
                    <div className="textCaracteristica">
                        <div className="title">E-commerce</div>
                        <div className="subtitle" style={{ maxWidth: '80%' }}>!Recibe pedidos por Whatsapp!</div>
                        <p style={{ maxWidth: '300px' }}>
                            Un menú completamente digital
                            recibe tus pedidos por whatsapp
                            sin comisiones - sin descargar APP
                        </p>
                    </div>
                    <div className="deluxePhoneMobil">
                        <div className="phoneElementalMobil" style={{ display: 'flex', justifyContent: 'center', position: 'relative' }}>
                            <div className="slider">
                                <Splide options={{
                                    type: 'loop',
                                    rewind: true,
                                }}>
                                    <SplideSlide >
                                        <img src={`./images/1Mobil.png`} alt="" className="imgSlider" />
                                    </SplideSlide>
                                    <SplideSlide>
                                        <img src={`./images/2Mobil.png`} alt="" className="imgSlider" />
                                    </SplideSlide>
                                    <SplideSlide>
                                        <img src={`./images/3Mobil.png`} alt="" className="imgSlider" />
                                    </SplideSlide>
                                    <SplideSlide>
                                        <img src={`./images/4Mobil.png`} alt="" className="imgSlider" />
                                    </SplideSlide>
                                </Splide>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section comprarGreen">
                    <div className="textCaracteristica">
                        <div className="title">Elige tu GreenCard</div>
                        <div className="subtitle">La que más se adapte a ti</div>
                    </div>
                    <div className="greenChoose">
                        <div className="content">
                            <div className="greenChoseHeat">
                                Elemental
                            </div>
                            {/*<br/>
                            <div>
                                <font><b>De <del>$3,000.00 MXN</del><br/><h2>a $1,500.00 MXN</h2></b></font>
                            </div>*/}
                            <div className="greenChoseBody">
                                <div>
                                    <i className="fas fa-check"></i>
                                    <font>Subdominio tunegocio.greencardpv.com</font>
                                </div>
                                <div>
                                    <i className="fas fa-check"></i>
                                    <font>SSL de seguridad</font>
                                </div>
                                <div>
                                    <i className="fas fa-check"></i>
                                    <font>Usuario y contraseña para diseñar <br />y personalizar las veces que quieras.</font>
                                </div>
                                <div>
                                    <i class="fas fa-check"></i>
                                    <font>Una tarjeta NFC y un QR digital.</font>
                                </div>
                                <div>
                                    <i class="fas fa-check"></i>
                                    <font> Integración de todas tus redes sociales,teléfono, correo y whatsapp.</font>
                                </div>
                            </div>
                            <div onClick={() => contactar('523223702372')} className="buttonChose">
                                <div>Comienza ahora</div>
                            </div>
                        </div>
                        <div className="content">
                            <div className="greenChoseHeat">
                                Deluxe
                            </div>
                            {/*<br/>
                            <div>
                                <font><b>De <del>$16,000.00 MXN</del><br/><h2>a $14,500.00 MXN</h2></b></font>
                            </div>*/}
                            <div className="greenChoseBody">
                                <div className>
                                    <i className="fas fa-check"></i>
                                    <font>Subdominio tunegocio.greencardpv.com</font>
                                </div>
                                <div>
                                    <i className="fas fa-check"></i>
                                    <font>SSL de seguridad</font>
                                </div>
                                <div>
                                    <i className="fas fa-check"></i>
                                    <font>Plataforma, usuario y contraseña para actualizar
                                        fotos o descripciones de tu producto o servicio</font>
                                </div>
                                <div>
                                    <i className="fas fa-check"></i>
                                    <font>Una tarjeta NFC y un QR digital.</font>
                                </div>
                                <div>
                                    <i className="fas fa-check"></i>
                                    <font>Integración de todas tus redes sociales y un Whatsapp</font>
                                </div>
                            </div>
                            <div className="buttonChose">
                                <div onClick={() => comienzaAhora('Deluxe')}>Comienza ahora</div>
                            </div>
                        </div>
                        <div className="content">
                            <div className="greenChoseHeat">
                                E-commerce
                            </div>
                            {/*<br/>
                            <div>
                                <font><b>De <del>$20,000.00 MXN</del><br/><h2>a $15,950.00 MXN</h2></b></font>
                            </div>*/}
                            <div className="greenChoseBody">
                                <div className>
                                    <i class="fas fa-check"></i>
                                    <font>Subdominio tunegocio.greencardpv.com</font>
                                </div>
                                <div>
                                    <i class="fas fa-check"></i>
                                    <font>SSL de seguridad</font>
                                </div>
                                <div>
                                    <i className="fas fa-check"></i>
                                    <font>Plataforma, usuario y contraseña para actualizar
                                        precios, dar de alta o baja  tus productos</font>
                                </div>
                                <div>
                                    <i class="fas fa-check"></i>
                                    <font>Sesión fotográfica de 20 productos</font>
                                </div>
                                <div>
                                    <i class="fas fa-check"></i>
                                    <font>Una tarjeta NFC con QR digital e impresión para su distribución</font>
                                </div>
                                <div>
                                    <i className="fas fa-check"></i>
                                    <font>Integración de todas tus redes sociales y un Whatsapp</font>
                                </div>
                            </div>
                            <div className="buttonChose">
                                <div onClick={() => comienzaAhora('E-commerce')}>Comienza ahora</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section" style={{ marginTop: '2em' }} id="Clientes">
                    <div className="textCaracteristica">
                        <div className="title">Nuestros Clientes</div>
                        <div className="subtitle" style={{ maxWidth: '80%' }}>Conoce las Greencard de nuestros clientes dando clic en cada uno de ellos</div>
                    </div>
                    <div className="slider">
                        <Splide options={{
                            type: 'slide',

                            perPage: 1,
                        }}>
                            <SplideSlide >
                                <img onClick={() => greenClientes('https://citlalyencarnacion.greencardpv.com/')} src={`./images/clientes/1.png`} alt="" className="imgSlider" />
                            </SplideSlide>
                            <SplideSlide>
                                <img onClick={() => greenClientes('https://alondralopez.greencardpv.com/')} src={`./images/clientes/2.png`} alt="" className="imgSlider" />
                            </SplideSlide>
                            <SplideSlide>
                                <img onClick={() => greenClientes('https://lavanderiapalacios.greencardpv.com/')} src={`./images/clientes/4.png`} alt="" className="imgSlider" />
                            </SplideSlide>
                            <SplideSlide>
                                <img onClick={() => greenClientes('https://redappleplace.greencardpv.com/')} src={`./images/clientes/5.png`} alt="" className="imgSlider" />
                            </SplideSlide>
                            <SplideSlide>
                                <img onClick={() => greenClientes('https://eduardonajar.greencardpv.com/')} src={`./images/clientes/sayulita.png`} alt="" className="imgSlider" />
                            </SplideSlide>
                            <SplideSlide>
                                <img onClick={() => greenClientes('https://amarantaramos.greencardpv.com/')} src={`./images/clientes/7.png`} alt="" className="imgSlider" />
                            </SplideSlide>
                            <SplideSlide>
                                <img onClick={() => greenClientes('https://laisla.greencardpv.com/')} src={`./images/clientes/laisla.png`} alt="" className="imgSlider" />
                            </SplideSlide>
                            <SplideSlide>
                                <img onClick={() => greenClientes('https://ismael.greencardpv.com/')} src={`./images/clientes/ismael.png`} alt="" className="imgSlider" />
                            </SplideSlide>
                            <SplideSlide>
                                <img onClick={() => greenClientes('https://chevallarta.greencardpv.com/')} src={`./images/clientes/chevallarta.png`} alt="" className="imgSlider" />
                            </SplideSlide>
                            <SplideSlide>
                                <img onClick={() => greenClientes('https://estrategiaintegral.greencardpv.com/')} src={`./images/clientes/estrategiaintegral.png`} alt="" className="imgSlider" />
                            </SplideSlide>
                            <SplideSlide>
                                <img onClick={() => greenClientes('https://cafeorigenes.greencardpv.com/')} src={`./images/clientes/cafeorigenes.png`} alt="" className="imgSlider" />
                            </SplideSlide>
                            <SplideSlide>
                                <img onClick={() => greenClientes('https://setac.greencardpv.com/')} src={`./images/clientes/setac.png`} alt="" className="imgSlider" />
                            </SplideSlide>
                        </Splide>
                    </div>
                    <div className="footer" style={{ paddingTop: '2em' }}>
                        <div className="buttonFooter" onClick={() => scrollTo('heroHome')}>
                            <div><i class="fas fa-arrow-up"></i></div>
                        </div>
                        <div className="containerFooter">
                            <div>
                                <h2 style={{ fontFamily: 'bwSurco' }}>
                                    Sobre nosotros
                                </h2>
                                <p className="textFooter">
                                    Nace de la necesidad de impulsar a profesionistas, emprendedores, pequeñas y medianas empresas a estar conectados con sus usuarios mediante la tecnología de una manera fácil y económica.
                                </p>
                            </div>
                            <div>
                                <h2 style={{ fontFamily: 'bwSurco' }}>
                                    Contáctanos
                                </h2>
                                <div className="contancto">
                                    <div onClick={() => contactar('523223702372')}><i className="fab fa-whatsapp"></i> 322 370 23 72</div>
                                    <div onClick={() => contactar('523223835647')}><i className="fab fa-whatsapp"></i> 322 383 56 47</div>
                                    <div><i className="far fa-envelope"></i> contacto@greencardpv.com</div>
                                </div>
                            </div>
                            <div>
                                <h2 style={{ fontFamily: 'bwSurco' }}>
                                    Síguenos en
                                </h2>
                                <div className="siguenos">
                                    <div onClick={() => window.location.href = 'https://www.instagram.com/greencardpv/'}><i class="fab fa-instagram"></i></div>
                                    <div onClick={() => window.location.href = 'https://www.facebook.com/Greencardpv-100316932042727'}><i class="fab fa-facebook-f"></i></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className="bodyHome">
                <div className="menuStycker">
                    <div className="menuStyckerLine" style={{ color: color }}>
                        <div onClick={() => scrollTo('heroHome')} className="menuStyckerButton">
                            Inicio
                        </div>
                        <div onClick={() => scrollTo('elemental')} className="menuStyckerButton">
                            Elemental
                        </div>
                        <div onClick={() => scrollTo('deluxe')} className="menuStyckerButton">
                            Deluxe
                        </div>
                        <div className="menuStyckerButton logoCarga" style={{ opacity: '0' }}>
                            <svg width="202" style={{ fill: '#3DE8F0' }} height="34" viewBox="0 0 202 34" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.284 18.024V22.488H17V23.892C17 27.636 14.912 28.752 12.248 28.752C8.972 28.752 6.524 25.98 6.524 20.04C6.524 14.1 8.936 11.328 12.068 11.328C13.796 11.328 15.128 12.336 16.064 14.028L21.428 11.904C19.088 7.8 15.596 6.72 12.212 6.72C4.832 6.72 0.512001 12.012 0.512001 20.04C0.512001 28.068 4.184 33.36 10.628 33.36C13.58 33.36 15.704 32.676 17.216 30.696C17.36 32.28 18.476 33.36 20.132 33.36C21.032 33.36 21.896 33.108 22.832 32.604V18.024H12.284Z" />
                                <path d="M37.1577 14.424C35.2857 14.424 33.4498 15.252 32.1178 17.592C32.1178 15.72 31.2177 14.424 29.7057 14.424C28.7337 14.424 27.6898 14.748 26.7178 15.18V33H32.2978V25.368C32.2978 20.868 33.7378 19.32 36.0418 19.32C36.9058 19.32 37.6977 19.572 38.3097 19.968L39.9298 15.036C39.1018 14.676 38.1297 14.424 37.1577 14.424Z" />
                                <path d="M50.0531 29.184C47.7131 29.184 46.5251 28.068 46.1291 26.16C46.9211 26.232 47.7131 26.304 48.5051 26.304C54.0851 26.304 57.4331 24.144 57.4331 20.508C57.4331 16.728 54.7691 14.424 50.1971 14.424C44.1131 14.424 40.5491 18.132 40.5491 24.324C40.5491 30.012 43.9331 33.36 49.6571 33.36C52.6811 33.36 55.1651 32.64 57.3971 31.236L55.2011 27.744C53.7611 28.68 52.1051 29.184 50.0531 29.184ZM49.6571 18.744C51.1691 18.744 52.0331 19.536 52.0331 20.76C52.0331 22.272 50.7371 23.136 48.4331 23.136C47.6771 23.136 46.8491 23.1 46.0211 23.028C46.3091 20.328 47.6411 18.744 49.6571 18.744Z" />
                                <path d="M69.5296 29.184C67.1896 29.184 66.0016 28.068 65.6056 26.16C66.3976 26.232 67.1896 26.304 67.9816 26.304C73.5616 26.304 76.9096 24.144 76.9096 20.508C76.9096 16.728 74.2456 14.424 69.6736 14.424C63.5896 14.424 60.0256 18.132 60.0256 24.324C60.0256 30.012 63.4096 33.36 69.1336 33.36C72.1576 33.36 74.6416 32.64 76.8736 31.236L74.6776 27.744C73.2376 28.68 71.5816 29.184 69.5296 29.184ZM69.1336 18.744C70.6456 18.744 71.5096 19.536 71.5096 20.76C71.5096 22.272 70.2136 23.136 67.9096 23.136C67.1536 23.136 66.3256 23.1 65.4976 23.028C65.7856 20.328 67.1176 18.744 69.1336 18.744Z" />
                                <path d="M91.6702 14.424C89.4382 14.424 87.3142 15.18 85.7662 17.376C85.6942 15.612 84.7942 14.424 83.3542 14.424C82.3822 14.424 81.3382 14.748 80.3662 15.18V33H85.9462V23.928C85.9462 20.112 87.4582 18.816 89.8702 18.816C91.5622 18.816 92.4622 20.148 92.4622 22.236V33H98.0422V21.264C98.0422 16.944 95.7022 14.424 91.6702 14.424Z" />
                                <path d="M117.895 31.272L116.131 27.384C114.871 28.32 112.927 28.932 111.235 28.932C108.607 28.932 106.987 27.06 106.987 23.856C106.987 20.688 108.319 18.816 110.587 18.816C111.883 18.816 112.747 19.752 113.215 21.156L117.283 19.536C116.131 15.324 112.531 14.424 110.299 14.424C105.223 14.424 101.335 17.916 101.335 23.856C101.335 29.832 104.863 33.36 110.767 33.36C113.251 33.36 115.915 32.712 117.895 31.272Z" />
                                <path d="M128.215 14.424C125.191 14.424 122.707 15.18 120.403 16.584L122.635 20.04C124.075 19.104 125.695 18.6 127.279 18.6C129.619 18.6 130.339 19.788 130.447 21.768C129.691 21.732 128.935 21.732 128.215 21.732C122.563 21.732 119.359 23.964 119.359 27.78C119.359 31.236 121.555 33.36 125.191 33.36C127.171 33.36 129.151 32.64 130.627 30.552C130.735 32.208 131.635 33.36 133.039 33.36C134.047 33.36 135.055 33.036 136.027 32.604V21.264C136.027 16.944 133.147 14.424 128.215 14.424ZM126.739 29.256C125.299 29.256 124.651 28.428 124.651 27.276C124.651 25.8 125.983 24.936 128.359 24.936C129.007 24.936 129.691 24.936 130.447 24.972C130.447 28.068 128.647 29.256 126.739 29.256Z" />
                                <path d="M150.502 14.424C148.63 14.424 146.794 15.252 145.462 17.592C145.462 15.72 144.562 14.424 143.05 14.424C142.078 14.424 141.034 14.748 140.062 15.18V33H145.642V25.368C145.642 20.868 147.082 19.32 149.386 19.32C150.25 19.32 151.042 19.572 151.654 19.968L153.274 15.036C152.446 14.676 151.474 14.424 150.502 14.424Z" />
                                <path d="M169.555 4.92C168.583 4.92 167.539 5.244 166.567 5.676V14.496C165.523 14.46 164.587 14.424 163.903 14.424C157.567 14.424 153.823 18.132 153.823 24.504C153.823 30.084 156.595 33.36 160.807 33.36C163.003 33.36 165.199 32.64 166.747 30.516C166.855 32.172 167.755 33.36 169.159 33.36C170.131 33.36 171.175 33.036 172.147 32.604V7.512C172.147 6 171.211 4.92 169.555 4.92ZM166.567 23.892C166.567 27.708 165.055 28.968 162.895 28.968C160.699 28.968 159.475 27.312 159.475 24.504C159.475 20.76 161.383 18.564 164.407 18.564C165.019 18.564 165.739 18.564 166.567 18.6V23.892Z" />
                                <path d="M183.976 6.616C182.512 6.616 181.048 7.096 180.016 8.536C179.944 7.384 179.368 6.616 178.408 6.616C177.76 6.616 177.064 6.832 176.416 7.12V24.52H180.136V19.192C180.832 19.216 181.48 19.24 181.936 19.24C186.136 19.24 188.632 16.768 188.632 12.544C188.632 8.824 186.784 6.616 183.976 6.616ZM181.576 16.48C181.168 16.48 180.688 16.48 180.136 16.456V12.952C180.136 10.408 181.168 9.544 182.608 9.544C184.072 9.544 184.864 10.672 184.864 12.544C184.864 15.04 183.616 16.48 181.576 16.48Z" />
                                <path d="M198.515 6.616L196.523 13.6L195.923 16.096L195.371 13.384L193.907 8.344C193.523 7 192.659 6.616 191.795 6.616C190.979 6.616 190.067 6.88 189.539 7.168L193.787 19H197.771L201.995 7.168L198.515 6.616Z" />
                                <path fillRule="evenodd" clipRule="evenodd" d="M118 9C117.526 9.6793 116.587 9.83973 115.892 9.38948C99.9363 -0.941518 84.8412 4.50965 78.1437 9.25259C77.4676 9.73136 76.5258 9.64022 76 9V9C75.4742 8.35978 75.5611 7.41504 76.2338 6.93148C83.5978 1.63757 100.194 -4.41178 117.609 6.92715C118.303 7.37917 118.474 8.32069 118 9V9Z" />
                            </svg>
                        </div>
                        <div onClick={() => scrollTo('ecomerce')} className="menuStyckerButton">
                            E-commerce
                        </div>
                        <div className="menuStyckerButton" onClick={() => scrollTo('clientes')}>
                            Clientes
                        </div>
                        {/*<div className="menuStyckerButton" style={{color:`#3de8f0`}} onClick={() => window.location='https://plataforma.greencardpv.com/'}>
                            Iniciar sesión
                        </div>*/}
                        <Link className="menuStyckerButton">

                        </Link>
                    </div>
                </div>
                <div className="section heroHome" id="heroHome">
                    <div className="phoneIzquierda"><img src="./images/izquierda.png" alt="" style={{ marginLeft: `-${margin}em` }} /></div>
                    <div className="titleHero">
                        <div className="contenHero">
                            <img src="./images/greencard.png" className="logoCentrado" alt="" style={{ marginTop: `-${margin}em` }} />
                            <p>Crea una experiencia única con tus clientes donde puedan obtener toda tu información, dando opción de cómo quieren conectarse contigo o tu negocio</p>
                            <div className="llamadoAccion">
                                <div onClick={() => contactar('523223702372')} className="containerAction">
                                    <div>OBTEN UN 50% DE DESCUENTO, COMIENZA AHORA</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="phoneDerecha"><img src="./images/derecha.png" alt="" style={{ marginRight: `-${margin}em` }} /></div>
                </div>
                <div className="section elemental" id="elemental" style={{ overflow: 'hidden' }}>
                    <div className="circle"></div>
                    <div className="textCaracteristica queSomos">
                        <div className="title">Elemental</div>
                        <div className="subtitle">Una Tarjeta digital diseñada por y para ti.</div>
                        <p>
                            Causa una buena primera impresión con Greencardpv ELEMENTAL tu tarjeta de presentación digital y sólo en segundos podrán acceder a tus datos de contacto sin descargar ningún APP.
                        </p>
                    </div>
                    <div className="queSomos">
                        <div className="labelsQueSomos">
                            <div className="labels" style={{ textAlign: 'right', marginRight: '1em' }}>Una tarjeta NFC con QR digital</div>
                            <div className="labels" style={{ textAlign: 'right', marginRight: '1em' }}>Integra todas tus Redes Sociales</div>
                        </div>
                        <img className="phoneCaracter" src="./images/phonecaracter.png" alt="" />
                        <div className="labelsQueSomos">
                            <div className="labels" style={{ marginLeft: '1em' }} >Subdominio tunegocio.greencardpv.com</div>
                            <div className="labels" style={{ marginLeft: '1em' }} >Diseño Personalizado de tu Greencardpv Digital</div>
                        </div>
                    </div>
                </div>
                <div className="llamadoAccion">
                    <div onClick={() => contactar('523223702372')} className="containerAction">
                        <div>OBTEN UN 50% DE DESCUENTO, COMIENZA AHORA</div>
                    </div>
                </div>
                <div className="section deluxe" id="deluxe" style={{ overflow: 'hidden' }}>
                    <div className="circleDos"></div>
                    <div className="DeluxeTitle">
                        <div className="title">Deluxe</div>
                        <div className="subtitle">Tu Catálogo o brochure de productos o servicios</div>
                        <p>
                            Crea una experiencia única con tus clientes Greencardpv DELUXE es un híbrido entre una página web y una app que solo en segundos podrán acceder a tus datos de contacto y servicios  desde su teléfonos sin descargar un APP.
                        </p>
                    </div>
                    <div className="circleSeis"></div>
                    <div className="DeluxeRigth">
                        <img src="./images/deluxe2.png" className="deluxePhone" style={{ marginLeft: '5em', opacity: '0', marginBottom: '-5em' }} alt="" />
                        <img src="./images/deluxe1.png" className="deluxePhone" style={{ marginLeft: '-5em', position: 'absolute', marginTop: '3em', opacity: '0', marginBottom: '-5em' }} alt="" />
                    </div>
                </div>
                <div className="section ecomerce" id="ecomerce">
                    <div className="circletres"></div>
                    <div className="circleCuatro"></div>
                    <div className="ecomerceLeft">
                        <img src="./images/1.png" className="ecomercePhone" style={{ opacity: '0' }} alt="" />
                        <img src="./images/2.png" className="ecomercePhone" style={{ opacity: '0', marginLeft: '-13em' }} alt="" />
                        <img src="./images/3.png" className="ecomercePhone" style={{ opacity: '0', marginLeft: '9em', marginTop: '-3em', marginBottom: '-5em' }} alt="" />
                        <img src="./images/4.png" className="ecomercePhone" style={{ opacity: '0', marginLeft: '-4em', marginTop: '-3em' }} alt="" />
                    </div>
                    <div className="DeluxeTitle">
                        <div className="title">E-commerce</div>
                        <div className="subtitle">!Recibe pedidos por Whatsapp!</div>
                        <p>
                            Greencardpv E-commerce  Comparte tu menú mediante un enlace donde tus clientes podrán verlo desde sus dispositivos móviles ofreciendo opciones de Ordenar, Pedir, Recoger o Entregar, podrás establecer los tiempos de tu trabajo, tarifas de envío y mínimos de pedidos para obtener la mejor satisfacción de tus clientes, Greendcardpv no te cobra tarifas adicionales, ni comisiones, todos tus ingresos por ventas son para ti.
                        </p>
                    </div>
                </div>
                <div className="section comprarGreen">
                    <div className="textCaracteristica">
                        <div className="title">Elige tu GreenCard</div>
                        <div className="subtitle">La que más se adapte a ti</div>
                    </div>
                    <div className="greenChoose">
                        <div className="content">
                            <div className="greenChoseHeat">
                                Elemental
                            </div>
                            {/*<br/>
                            <div>
                                <font><b>De <del>$3,000.00 MXN</del><br/><h2>a $1,500.00 MXN</h2></b></font>
                            </div>*/}
                            <div className="greenChoseBody">
                                <div>
                                    <i className="fas fa-check"></i>
                                    <font>Subdominio tunegocio.greencardpv.com</font>
                                </div>
                                <div>
                                    <i className="fas fa-check"></i>
                                    <font>SSL de seguridad</font>
                                </div>
                                <div>
                                    <i className="fas fa-check"></i>
                                    <font>Usuario y contraseña para diseñar <br />y personalizar las veces que quieras.</font>
                                </div>
                                <div>
                                    <i class="fas fa-check"></i>
                                    <font>Una tarjeta NFC y un QR digital.</font>
                                </div>
                                <div>
                                    <i class="fas fa-check"></i>
                                    <font> Integración de todas tus redes sociales,teléfono, correo y whatsapp.</font>
                                </div>
                            </div>
                            <div onClick={() => contactar('523223702372')} className="buttonChose">
                                <div>Comienza ahora</div>
                            </div>
                        </div>
                        <div className="content">
                            <div className="greenChoseHeat">
                                Deluxe
                            </div>
                            {/*<br/>
                            <div>
                                <font><b>De <del>$16,000.00 MXN</del><br/><h2>a $14,500.00 MXN</h2></b></font>
                            </div>*/}
                            <div className="greenChoseBody">
                                <div>
                                    <i className="fas fa-check"></i>
                                    <font>Subdominio tunegocio.greencardpv.com</font>
                                </div>
                                <div>
                                    <i className="fas fa-check"></i>
                                    <font>SSL de seguridad</font>
                                </div>
                                <div>
                                    <i className="fas fa-check"></i>
                                    <font>Plataforma, usuario y contraseña para actualizar
                                        fotos o descripciones de tu producto o servicio</font>
                                </div>
                                <div>
                                    <i className="fas fa-check"></i>
                                    <font>Una tarjeta NFC y un QR digital.</font>
                                </div>
                                <div>
                                    <i className="fas fa-check"></i>
                                    <font>Integración de todas tus redes sociales y un Whatsapp</font>
                                </div>
                            </div>
                            <div className="buttonChose">
                                <div onClick={() => comienzaAhora('Deluxe')}>Comienza ahora</div>
                            </div>
                        </div>
                        <div className="content">
                            <div className="greenChoseHeat">
                                E-commerce
                            </div>
                            {/*<br/>
                            <div>
                                <font><b>De <del>$20,000.00 MXN</del><br/><h2>a $15,950.00 MXN</h2></b></font>
                            </div>*/}
                            <div className="greenChoseBody">
                                <div>
                                    <i class="fas fa-check"></i>
                                    <font>Subdominio tunegocio.greencardpv.com</font>
                                </div>
                                <div>
                                    <i class="fas fa-check"></i>
                                    <font>SSL de seguridad</font>
                                </div>
                                <div>
                                    <i className="fas fa-check"></i>
                                    <font>Plataforma, usuario y contraseña para actualizar
                                        precios, dar de alta o baja  tus productos</font>
                                </div>
                                <div>
                                    <i class="fas fa-check"></i>
                                    <font>Sesión fotográfica de 20 productos</font>
                                </div>
                                <div>
                                    <i class="fas fa-check"></i>
                                    <font>Una tarjeta NFC con QR digital e impresión para su distribución</font>
                                </div>
                                <div>
                                    <i className="fas fa-check"></i>
                                    <font>Integración de todas tus redes sociales y un Whatsapp</font>
                                </div>
                            </div>
                            <div className="buttonChose">
                                <div onClick={() => comienzaAhora('E-commerce')}>Comienza ahora</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section clientes" id="clientes">
                    <div className="textCaracteristica">
                        <div className="title">Nuestros Clientes</div>
                        <div className="subtitle">Conoce las Greencard de nuestros clientes dando clic en cada uno de ellos</div>
                    </div>
                    <div className="slider">
                        <Splide options={{
                            type: 'loop',
                            rewind: true,
                            width: 900,
                            perPage: 4,
                            gap: '1em',
                        }}>
                            <SplideSlide >
                                <img onClick={() => greenClientes('https://citlalyencarnacion.greencardpv.com/')} src={`./images/clientes/1.png`} alt="" className="imgSlider" />
                            </SplideSlide>
                            <SplideSlide>
                                <img onClick={() => greenClientes('https://alondralopez.greencardpv.com/')} src={`./images/clientes/2.png`} alt="" className="imgSlider" />
                            </SplideSlide>
                            <SplideSlide>
                                <img onClick={() => greenClientes('https://lavanderiapalacios.greencardpv.com/')} src={`./images/clientes/4.png`} alt="" className="imgSlider" />
                            </SplideSlide>
                            <SplideSlide>
                                <img onClick={() => greenClientes('https://redappleplace.greencardpv.com/')} src={`./images/clientes/5.png`} alt="" className="imgSlider" />
                            </SplideSlide>
                            <SplideSlide>
                                <img onClick={() => greenClientes('https://eduardonajar.greencardpv.com/')} src={`./images/clientes/sayulita.png`} alt="" className="imgSlider" />
                            </SplideSlide>
                            <SplideSlide>
                                <img onClick={() => greenClientes('https://amarantaramos.greencardpv.com/')} src={`./images/clientes/7.png`} alt="" className="imgSlider" />
                            </SplideSlide>
                            <SplideSlide>
                                <img onClick={() => greenClientes('https://laisla.greencardpv.com/')} src={`./images/clientes/laisla.png`} alt="" className="imgSlider" />
                            </SplideSlide>
                            <SplideSlide>
                                <img onClick={() => greenClientes('https://ismael.greencardpv.com/')} src={`./images/clientes/ismael.png`} alt="" className="imgSlider" />
                            </SplideSlide>
                            <SplideSlide>
                                <img onClick={() => greenClientes('https://chevallarta.greencardpv.com/')} src={`./images/clientes/chevallarta.png`} alt="" className="imgSlider" />
                            </SplideSlide>
                            <SplideSlide>
                                <img onClick={() => greenClientes('https://estrategiaintegral.greencardpv.com/')} src={`./images/clientes/estrategiaintegral.png`} alt="" className="imgSlider" />
                            </SplideSlide>
                            <SplideSlide>
                                <img onClick={() => greenClientes('https://cafeorigenes.greencardpv.com/')} src={`./images/clientes/cafeorigenes.png`} alt="" className="imgSlider" />
                            </SplideSlide>
                            <SplideSlide>
                                <img onClick={() => greenClientes('https://setac.greencardpv.com/')} src={`./images/clientes/setac.png`} alt="" className="imgSlider" />
                            </SplideSlide>
                        </Splide>
                    </div>
                    <div className="footer" style={{ paddingTop: '2em' }}>
                        <div className="buttonFooter" onClick={() => scrollTo('heroHome')}>
                            <div><i class="fas fa-arrow-up"></i></div>
                        </div>
                        <div className="containerFooter">
                            <div>
                                <h2 style={{ fontFamily: 'bwSurco' }}>
                                    Sobre nosotros
                                </h2>
                                <p className="textFooter">
                                    Nace de la necesidad de impulsar a profesionistas, emprendedores, pequeñas y medianas empresas a estar conectados con sus usuarios mediante la tecnología de una manera fácil y económica.
                                </p>
                            </div>
                            <div>
                                <h2 style={{ fontFamily: 'bwSurco' }}>
                                    Contáctanos
                                </h2>
                                <div className="contancto">
                                    <div onClick={() => contactar('523223702372')}><i className="fab fa-whatsapp"></i> 322 370 23 72</div>
                                    <div onClick={() => contactar('523223835647')}><i className="fab fa-whatsapp"></i> 322 383 56 47</div>
                                    <div><i className="far fa-envelope"></i> contacto@greencardpv.com</div>
                                </div>
                            </div>
                            <div>
                                <h2 style={{ fontFamily: 'bwSurco' }}>
                                    Síguenos en
                                </h2>
                                <div className="siguenos">
                                    <div onClick={() => window.location.href = 'https://www.instagram.com/greencardpv/'}><i class="fab fa-instagram"></i></div>
                                    <div onClick={() => window.location.href = 'https://www.facebook.com/Greencardpv-100316932042727'}><i class="fab fa-facebook-f"></i></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}
