
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import Login from './pages/login'
import Payme from './pages/payme'
import Create from './pages/createcard'

import Home from './pages/home'
import Dashboard from './pages/dashboard'
import HomeDashboard from './components/home'
import { Provider } from 'react-redux'
import store from './redux/store'
import Autocontext from './redux/autocontext'

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Switch>
          <Route exact path="/" component={Home}/>
          <Route path='*'>
            <h1>404 Not Found </h1>
          </Route>
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
