import Axios from 'axios'
import React, { useState } from 'react'
import './login.css'
import {connect} from 'react-redux'
import { usuario_action } from '../redux/actions/usuarioActions'
import {Redirect} from 'react-router-dom'

function Login(props) {
    const [display, setstate] = useState('none')
    const [contrasena, setcontrasena] = useState('')
    const [contrasena2, setcontrasena2] = useState('')
    const [Token, setToken] = useState(null)
    const login = (e) => {
        e.preventDefault()
        Axios({
            url: "https://www.greencardpv.com/api-greencardpv/api/login.php",
            method: "post",
            responseType: "json",
            data: {
                email: document.getElementById('loginEmail').value,
                password: document.getElementById('loginPassword').value,
            },
        }).then(respuesta => {
            props.usuario_action(respuesta.data)
        }).catch(error => {
            console.log(error) // 401
        })
    }
    const prueba = (e) => {
        e.preventDefault()
        Axios({
            url: "https://www.greencardpv.com/api-greencardpv/api/register.php",
            method: "post",
            responseType: "json",
            headers: {
                'Authorization': `${Token}` 
            },
            data: {
                subdominio: "prueba",
            },
        }).then(respuesta => {
            console.log(respuesta)
        })
    }
    if (props.usuario !== false && props.usuario.exito==1) {
        return(
            <Redirect to="/dashboard" /> 
       )
    }else{
        return (
            <div className="login">
                <div className="formularioLogin">
                    <form className="formRegistro" required onSubmit={(e) => login(e)}>
                        <div className="logoFormulario"><img src="./images/greencard.png" /></div>
                        <input className="inputLogin" id="loginEmail" required type="text" placeholder="Email" />
                        <input className="inputLogin" id="loginPassword" required type="password" placeholder="contraseña" />
                        <button className="buttonLogin" >Iniciar Sesion</button>
                        <div id="alertLogin" className="alertLogin alertLoginClose">Tu email y/o contraseña son incorrectos</div>
    
                    </form>
                    <div className="line" />
                    <div className="registro"><label>¿no tienes cuenta aun?</label>
                        <button id="buttonRegistro" className="buttonRegistro" onClick={() => setstate('')}> Registrate</button>
                    </div>
    
                    <form onSubmit={(e) => prueba(e)} className="formRegistro" required style={{ display: display }}>
                        <input className="inputLogin" required type="Email" placeholder="Correo Electrónico" />
                        <input className="inputLogin" required type="text" placeholder="Nombre(s)" />
                        <input className="inputLogin" required type="text" placeholder="Apellido(s)" />
                        <input className="inputLogin" required onChange={(e) => setcontrasena(e.target.value)} type="password" placeholder="contraseña" />
                        <input className="inputLogin" required onChange={(e) => setcontrasena2(e.target.value)} type="password" placeholder="contraseña" />
                        <button className="buttonLogin" style={{ display: display }}>Registrarse</button>
                    </form>
    
                </div>
                <div className="imagenesFormulario">
                    <div className="containerPhoneLogin">
                        <img className='phone' src="../images/phone.png" alt="" />
                    </div>
                    <div className="circlesLogin">
                        <div className="retangle"><div style={{width:'10em',height:'10em'}} className="circleLogin"></div></div>
                        <div className="retangle"><div style={{width:'15em',height:'15em'}} className="circleLogin"></div></div>
                        <div className="retangle"><div style={{width:'5em',height:'5em'}} className="circleLogin"></div></div>
                        <div className="retangle"><div style={{width:'20em',height:'20em'}} className="circleLogin"></div></div>
                    </div>
                </div>
    
            </div>
        )
    }
}

const PropsStore = state => {
    return {
        usuario: state.usuario.usuario
    }
}
const functionStore = {
    usuario_action
}


export default connect(PropsStore,functionStore)(Login)
