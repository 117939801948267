import Axios from 'axios'
import React, { useState } from 'react'
import './dashboard.css'
import {connect} from 'react-redux'
import { usuario_action } from '../redux/actions/usuarioActions'
function Dashboard(props) {

    const search = window.location.search;
    console.log("Search: ",search);
    if(search!=="" || search!=false){
        const params = new URLSearchParams(search);
        const collection_id = params.get('collection_id');
        const collection_status = params.get('collection_status');
        const payment_id = params.get('payment_id');
        const status = params.get('status');
        const external_reference = params.get('external_reference');
        const payment_type = params.get('payment_type');
        const merchant_order_id = params.get('merchant_order_id');
        const preference_id = params.get('preference_id');
        const site_id = params.get('site_id');
        const processing_mode = params.get('processing_mode');
        const merchant_account_id = params.get('merchant_account_id');
        
        console.log("collection_id: "+collection_id+" collection_status: "+collection_status+" payment_id: "+payment_id);

        //Guardar datos de mercado pago
        Axios({
            url: "https://www.greencardpv.com/api-greencardpv/api/confirmacion.php",
            method: "post",
            responseType: "json",
            data: {
                collection_id: collection_id,
                collection_status: collection_status,
                payment_id: payment_id,
                status: status,
                external_reference: external_reference,
                payment_type: payment_type,
                merchant_order_id: merchant_order_id,
                preference_id: preference_id,
                site_id: site_id,
                processing_mode: processing_mode,
                merchant_account_id: merchant_account_id
            },
        }).then(respuesta => {
            props.usuario_action(respuesta.data)
        }).catch(error => {
            console.log(error) // 401
        })
    }
    return (
        <div className="dashboard">
            <div className="dashboardBackground"></div>
            <div className="sidebar">
                <div className="sidebarContainer">
                    <div className="logoDashboard">
                        <svg width="202" style={{ fill: '#3DE8F0' }} height="34" viewBox="0 0 202 34" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.284 18.024V22.488H17V23.892C17 27.636 14.912 28.752 12.248 28.752C8.972 28.752 6.524 25.98 6.524 20.04C6.524 14.1 8.936 11.328 12.068 11.328C13.796 11.328 15.128 12.336 16.064 14.028L21.428 11.904C19.088 7.8 15.596 6.72 12.212 6.72C4.832 6.72 0.512001 12.012 0.512001 20.04C0.512001 28.068 4.184 33.36 10.628 33.36C13.58 33.36 15.704 32.676 17.216 30.696C17.36 32.28 18.476 33.36 20.132 33.36C21.032 33.36 21.896 33.108 22.832 32.604V18.024H12.284Z" />
                            <path d="M37.1577 14.424C35.2857 14.424 33.4498 15.252 32.1178 17.592C32.1178 15.72 31.2177 14.424 29.7057 14.424C28.7337 14.424 27.6898 14.748 26.7178 15.18V33H32.2978V25.368C32.2978 20.868 33.7378 19.32 36.0418 19.32C36.9058 19.32 37.6977 19.572 38.3097 19.968L39.9298 15.036C39.1018 14.676 38.1297 14.424 37.1577 14.424Z" />
                            <path d="M50.0531 29.184C47.7131 29.184 46.5251 28.068 46.1291 26.16C46.9211 26.232 47.7131 26.304 48.5051 26.304C54.0851 26.304 57.4331 24.144 57.4331 20.508C57.4331 16.728 54.7691 14.424 50.1971 14.424C44.1131 14.424 40.5491 18.132 40.5491 24.324C40.5491 30.012 43.9331 33.36 49.6571 33.36C52.6811 33.36 55.1651 32.64 57.3971 31.236L55.2011 27.744C53.7611 28.68 52.1051 29.184 50.0531 29.184ZM49.6571 18.744C51.1691 18.744 52.0331 19.536 52.0331 20.76C52.0331 22.272 50.7371 23.136 48.4331 23.136C47.6771 23.136 46.8491 23.1 46.0211 23.028C46.3091 20.328 47.6411 18.744 49.6571 18.744Z" />
                            <path d="M69.5296 29.184C67.1896 29.184 66.0016 28.068 65.6056 26.16C66.3976 26.232 67.1896 26.304 67.9816 26.304C73.5616 26.304 76.9096 24.144 76.9096 20.508C76.9096 16.728 74.2456 14.424 69.6736 14.424C63.5896 14.424 60.0256 18.132 60.0256 24.324C60.0256 30.012 63.4096 33.36 69.1336 33.36C72.1576 33.36 74.6416 32.64 76.8736 31.236L74.6776 27.744C73.2376 28.68 71.5816 29.184 69.5296 29.184ZM69.1336 18.744C70.6456 18.744 71.5096 19.536 71.5096 20.76C71.5096 22.272 70.2136 23.136 67.9096 23.136C67.1536 23.136 66.3256 23.1 65.4976 23.028C65.7856 20.328 67.1176 18.744 69.1336 18.744Z" />
                            <path d="M91.6702 14.424C89.4382 14.424 87.3142 15.18 85.7662 17.376C85.6942 15.612 84.7942 14.424 83.3542 14.424C82.3822 14.424 81.3382 14.748 80.3662 15.18V33H85.9462V23.928C85.9462 20.112 87.4582 18.816 89.8702 18.816C91.5622 18.816 92.4622 20.148 92.4622 22.236V33H98.0422V21.264C98.0422 16.944 95.7022 14.424 91.6702 14.424Z" />
                            <path d="M117.895 31.272L116.131 27.384C114.871 28.32 112.927 28.932 111.235 28.932C108.607 28.932 106.987 27.06 106.987 23.856C106.987 20.688 108.319 18.816 110.587 18.816C111.883 18.816 112.747 19.752 113.215 21.156L117.283 19.536C116.131 15.324 112.531 14.424 110.299 14.424C105.223 14.424 101.335 17.916 101.335 23.856C101.335 29.832 104.863 33.36 110.767 33.36C113.251 33.36 115.915 32.712 117.895 31.272Z" />
                            <path d="M128.215 14.424C125.191 14.424 122.707 15.18 120.403 16.584L122.635 20.04C124.075 19.104 125.695 18.6 127.279 18.6C129.619 18.6 130.339 19.788 130.447 21.768C129.691 21.732 128.935 21.732 128.215 21.732C122.563 21.732 119.359 23.964 119.359 27.78C119.359 31.236 121.555 33.36 125.191 33.36C127.171 33.36 129.151 32.64 130.627 30.552C130.735 32.208 131.635 33.36 133.039 33.36C134.047 33.36 135.055 33.036 136.027 32.604V21.264C136.027 16.944 133.147 14.424 128.215 14.424ZM126.739 29.256C125.299 29.256 124.651 28.428 124.651 27.276C124.651 25.8 125.983 24.936 128.359 24.936C129.007 24.936 129.691 24.936 130.447 24.972C130.447 28.068 128.647 29.256 126.739 29.256Z" />
                            <path d="M150.502 14.424C148.63 14.424 146.794 15.252 145.462 17.592C145.462 15.72 144.562 14.424 143.05 14.424C142.078 14.424 141.034 14.748 140.062 15.18V33H145.642V25.368C145.642 20.868 147.082 19.32 149.386 19.32C150.25 19.32 151.042 19.572 151.654 19.968L153.274 15.036C152.446 14.676 151.474 14.424 150.502 14.424Z" />
                            <path d="M169.555 4.92C168.583 4.92 167.539 5.244 166.567 5.676V14.496C165.523 14.46 164.587 14.424 163.903 14.424C157.567 14.424 153.823 18.132 153.823 24.504C153.823 30.084 156.595 33.36 160.807 33.36C163.003 33.36 165.199 32.64 166.747 30.516C166.855 32.172 167.755 33.36 169.159 33.36C170.131 33.36 171.175 33.036 172.147 32.604V7.512C172.147 6 171.211 4.92 169.555 4.92ZM166.567 23.892C166.567 27.708 165.055 28.968 162.895 28.968C160.699 28.968 159.475 27.312 159.475 24.504C159.475 20.76 161.383 18.564 164.407 18.564C165.019 18.564 165.739 18.564 166.567 18.6V23.892Z" />
                            <path d="M183.976 6.616C182.512 6.616 181.048 7.096 180.016 8.536C179.944 7.384 179.368 6.616 178.408 6.616C177.76 6.616 177.064 6.832 176.416 7.12V24.52H180.136V19.192C180.832 19.216 181.48 19.24 181.936 19.24C186.136 19.24 188.632 16.768 188.632 12.544C188.632 8.824 186.784 6.616 183.976 6.616ZM181.576 16.48C181.168 16.48 180.688 16.48 180.136 16.456V12.952C180.136 10.408 181.168 9.544 182.608 9.544C184.072 9.544 184.864 10.672 184.864 12.544C184.864 15.04 183.616 16.48 181.576 16.48Z" />
                            <path d="M198.515 6.616L196.523 13.6L195.923 16.096L195.371 13.384L193.907 8.344C193.523 7 192.659 6.616 191.795 6.616C190.979 6.616 190.067 6.88 189.539 7.168L193.787 19H197.771L201.995 7.168L198.515 6.616Z" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M118 9C117.526 9.6793 116.587 9.83973 115.892 9.38948C99.9363 -0.941518 84.8412 4.50965 78.1437 9.25259C77.4676 9.73136 76.5258 9.64022 76 9V9C75.4742 8.35978 75.5611 7.41504 76.2338 6.93148C83.5978 1.63757 100.194 -4.41178 117.609 6.92715C118.303 7.37917 118.474 8.32069 118 9V9Z" />
                        </svg>
                    </div>
                    <div className="menuDashboard">
                        <div><i className="fas fa-th-large"></i> Inicio</div>
                        <div><i className="fas fa-mobile-alt"></i> Tus GreenCardPv</div>
                        <div><i className="fas fa-money-bill-wave"></i> Estado de cuenta</div>
                        <div><i className="fas fa-cog"></i> Ajustes</div>
                    </div>
                </div>
            </div>
            <div className="dashboardRight">
                <div className="containerDashboard">
                    <div className="containerDashboardHeader">
                        <div className="perfilDashboard"><div className="perfilNavbar" style={{ backgroundImage: `url('./images/perfil.jpg')` }}></div></div>
                        <div className="nameDashboard">Emmanuel Aviles</div>
                        <div className="outDashboard" onClick={()=>props.usuario_action(false)}> <i className="fas fa-sign-out-alt"></i></div>
                    </div>
                    <div className="containerDashboardBody">
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    )
}

const PropsStore = state => {
    return {
        usuario: state.usuario.usuario
    }
}
const functionStore = {
    usuario_action
}

export default connect(PropsStore,functionStore)(Dashboard)
