import {createStore} from 'redux'
import Reducers from './reducers/index'
import {loadState, saveState} from './localStorage'

//Para verlo en navegador
import {composeWithDevTools} from 'redux-devtools-extension'


const initialState = loadState()


const store = createStore(Reducers,initialState,composeWithDevTools())

store.subscribe( ()=> {
    console.log('cambio el estado')
    saveState(store.getState())
  })

export default store