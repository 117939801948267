import {USUARIO} from '../actions/usuarioActions'

const default_data={
    usuario:false,
}

const usuario = (state=default_data,action)=>{
    switch(action.type){
        case USUARIO:{
            return{
                ...state,usuario:action.payload
            }
        }
        default: return state
    }
}

export default usuario;