import {connect} from 'react-redux'
import {Redirect} from 'react-router-dom'

function Autocontext(props) {
   if (props.usuario.exito==1) {
       return(
           <>
           { props.children}
           </>
       )
   }else{
       return(
            <Redirect to="/login" /> 
       )
   }
}



const PropsStore = state => {
    return {
        usuario: state.usuario.usuario
    }
}


export default connect(PropsStore,null)(Autocontext)

