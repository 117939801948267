import React, { useState, useEffect } from 'react'
import './estilos.css'
import { gsap } from "gsap";
import faker from 'faker'
import { map } from 'async';
import Axios from 'axios'
import { connect } from 'react-redux'
import { useMercadopago } from 'react-sdk-mercadopago';

function Home(props) {
    const greenCards = 0
    const [imagen, setImagen] = useState('./images/coverElemental.jpg')
    const [logo, setLogo] = useState('./images/greencard.png')
    const [color, setColor] = useState('#420d67')
    const [eslogan, setEslogan] = useState(`Obtén tu Greencard elemental`)
    const [id, setId] = useState(0)
    const [index, setIndex] = useState(0)
    const [subdominio, setSubdominio] = useState('')
    const [idMercado, setMercadoPago] = useState('')
    const [redSocial, setRedSocial] = useState([{
        id: faker.datatype.uuid(),
        icono: 'fas fa-plus-square',
        link: ''
    }])
    const mercadopago = useMercadopago.v2('TEST-1ac9e827-3a78-4c2b-ae22-738df3c488f6', {
        locale: 'en-US'
    });
    const [icons, setIcons] = useState([
        'fab fa-instagram',
        'fab fa-tumblr',
        'fab fa-pinterest',
        'fab fa-telegram',
        'fab fa-snapchat',
        'fas fa-phone',
        'fas fa-at',
        'fab fa-twitter',
        'fab fa-vk',
        'fab fa-linkedin-in',
        'fab fa-vine',
        'fab fa-facebook-f',
        'fab fa-youtube',
        'fas fa-globe',
        'fab fa-whatsapp',
        'fab fa-skype',
        'fab fa-reddit',
        'fab fa-vimeo',
        'fab fa-tiktok',
        'fab fa-twitch',
        'fab fa-behance',
        'fab fa-dribbble',
        'fab fa-facebook-messenger',
        'fab fa-airbnb',
        'fas fa-lock',
        'fab fa-patreon',
        'fab fa-discord',
        'fas fa-music',
        'fab fa-spotify',
        'fab fa-soundcloud',
    ])
    const handleCreenCard = () => {
        gsap.to('.createGreenCardContainer', {
            opacity: 0,
            marginTop: '-10%',
            display: 'none'
        })
        gsap.to('.containerPhoneCreate', {
            opacity: 1,
            marginTop: '0',
            display: 'block',
            delay: 0.5
        })
    }
    const handleTextTarea = () => {
        gsap.to('.textareaContainerPhoneCreate', {
            opacity: 1,
            display: 'flex'
        })
    }
    const handleModalSocial = () => {
        gsap.to('.ModalSocialContainerPhoneCreate', {
            opacity: 1,
            display: 'flex'
        })
    }
    const handleConfirmar = () => {
        gsap.to('.textareaContainerPhoneCreate', {
            opacity: 0,
            display: 'none'
        })
    }
    const inputComprobar = (e, event) => {
        if (e.target.files[0]) {
            if (event === 'logo') {
                setLogo(URL.createObjectURL(e.target.files[0]))
            } else {
                setImagen(URL.createObjectURL(e.target.files[0]))
            }
        }
    }
    const agregarLink = () => {
        if (redSocial.length < 6) {
            setRedSocial(oldArray => [...oldArray, {
                id: faker.random.uuid(),
                icono: 'fas fa-plus-square',
                link: ''
            }])
        }

    }
    const deleteRedSocial = (id) => {
        if (redSocial.length > 1) {
            const arrayUpdate = redSocial.filter(red => red.id !== id)
            setRedSocial(arrayUpdate)
        }
    }
    const selectIcon = (index, id) => {
        setId(id)
        setIndex(index)
        gsap.to('.iconsContainerSocialPhoneCreate', {
            opacity: 1,
            display: 'grid'
        })
    }
    const handleIcon = (icon) => {
        let arrayCopy = [...redSocial]
        arrayCopy[index].icono = icon
        setRedSocial(arrayCopy)

        const element = document.getElementById(id)
        element.removeChild(element.firstChild)
        element.innerHTML = `<i className="${icon}"></i>`

        const elements = document.getElementsByClassName(id)

        for (let index = 0; index < elements.length; index++) {
            elements[index].removeChild(elements[index].firstChild)
            elements[index].innerHTML = `<i class="${icon}"></i>`

        }

        const comprueba = redSocial.find(red => red.icono === 'fas fa-plus-square')
        if (!comprueba) {
            const elementosCircle = document.querySelectorAll('.circleContainerSocialPhoneCreate')
            for (let index = 0; index < elementosCircle.length; index++) {
                elementosCircle[index].style.border = '1px solid #ffff'
            }
        }
        closeIcons()
    }
    const closeIcons = () => {
        gsap.to('.iconsContainerSocialPhoneCreate', {
            opacity: 0,
            display: 'none'
        })
    }
    const handleRedesSociales = (e) => {
        e.preventDefault()
        const comprueba = redSocial.find(red => red.icono === 'fas fa-plus-square')
        if (comprueba) {
            const elementosCircle = document.querySelectorAll('.fa-plus-square')
            for (let index = 0; index < elementosCircle.length; index++) {
                elementosCircle[index].parentElement.style.border = '1px solid #ee6262'
            }
        } else {
            gsap.to('.ModalSocialContainerPhoneCreate', {
                opacity: 1,
                display: 'none'
            })
        }
    }
    useEffect(async () => {
        gsap.to('.containerLabelPhoneCreate', {
            delay: 2,
            opacity: 1,
            stagger: 0.5,
        })


    }, [])
    const handleCrearGreen = () => {
        gsap.to('.containerPhoneCreate', {
            opacity: 0,
            marginTop: '-10%',
            display: 'none'
        })
        gsap.to('.containerUrlDisponible', {
            opacity: 1,
            marginTop: '0',
            display: 'flex',
            delay: 0.5
        })
    }
    const handleSubdominio = (e) => {
        const text = e.target.value

        if (/^[a-zA-Z]*$/g.test(text)) {
            setSubdominio(text.split(" ").join(""))
        }
        //setSubdominio(text.split(expresionRegular).join(""))
    }
    const checkUrl = () => {
        gsap.to('.containerUrlDisponible', {
            opacity: 0,
            marginTop: '-10%',
            display: 'none'
        })
        gsap.to('.containerPago', {
            opacity: 1,
            marginTop: '0',
            display: 'flex',
            delay: 0.5
        })
        console.log(idMercado)
        const script = document.createElement("script");
        script.src = "https://www.mercadopago.com.mx/integrations/v1/web-payment-checkout.js";
        script.dataset.preferenceId = idMercado
        script.id = "buttonMercado"
        document.getElementById("formPayme").appendChild(script);
    }
    if (greenCards >= 1) {
        return (
            <div className="divContainerDashboard">
                <div className="containerNewCard">
                    aqui estan tus greenCards
                </div>
            </div>
        )
    } else {
        return (
            <div className="divContainerDashboardCreate">
                <div className="containerNewCard">
                    <div className="createGreenCardContainer">
                        <div>Comencemos creando tu primer Greencard</div>
                        <div className="eligeGreen">
                            <div onClick={() => handleCreenCard()}>Elemental</div>
                        </div>
                    </div>
                    <div className="containerPhoneCreate">
                        <div className="tutorialContainerPhoneCreate">
                            <div className="containerLabelPhoneCreate" style={{ left: '-200px', top: '20px', width: '220px' }}>
                                <label htmlFor="">Elijamos tu imagen de perfil</label>
                                <div className="lineContainerPhoneCreate"></div>
                            </div>
                            <div className="containerLabelPhoneCreate" style={{ right: '-200px', top: '250px', width: '220px', textAlign: 'right' }}>
                                <label htmlFor="">Agreguemos un título</label>
                                <div className="lineContainerPhoneCreate"></div>
                            </div>
                            <div className="containerLabelPhoneCreate" style={{ right: '-200px', bottom: '100px', width: '220px', textAlign: 'right' }}>
                                <label htmlFor="">Selecciona tus redes <br />sociales favoritas</label>
                                <div className="lineContainerPhoneCreate"></div>
                            </div>
                            <div className="containerLabelPhoneCreate" style={{ left: '-200px', top: '320px', width: '250px' }}>
                                <label htmlFor="">Coloquemos tu logo</label>
                                <div className="lineContainerPhoneCreate"></div>
                            </div>
                            <div className="containerLabelPhoneCreate" style={{ right: '-200px', bottom: '20px', width: '220px', textAlign: 'right' }}>
                                <label htmlFor="">Elije tu color favorito</label>
                                <div className="lineContainerPhoneCreate"></div>
                            </div>
                        </div>
                        <div className="botonesContainerPhoneCreate">
                            <div className="buttonContainerEdit" onClick={() => { document.getElementById('fileImagen').click() }} style={{ left: '1em', top: '1em' }}>
                                <i className="fas fa-plus-circle"></i>
                                <input type="file" className="inputFile" id="fileImagen" accept="image/jpeg" onChange={(e) => inputComprobar(e, 'background')}></input>
                            </div>
                            <div className="buttonContainerEdit" onClick={() => handleTextTarea()} style={{ right: '1em', top: '250px' }}>
                                <i className="fas fa-plus-circle"></i>
                            </div>
                            <div className="buttonContainerEdit" onClick={() => { document.getElementById('fileImagenLogo').click() }} style={{ left: '50px', top: '320px' }}>
                                <i className="fas fa-plus-circle"></i>
                                <input type="file" className="inputFile" id="fileImagenLogo" accept="image/*" onChange={(e) => inputComprobar(e, 'logo')}></input>
                            </div>
                            <div className="buttonContainerEdit" onClick={() => { document.getElementById('inputFileColor').click() }} style={{ right: '20px', bottom: '20px' }}>
                                <i className="fas fa-plus-circle"></i>
                                <input type="color" className="inputFile" id="inputFileColor" onChange={(e) => setColor(e.target.value)} />
                            </div>
                            <div className="buttonBotoneraSocialReds" onClick={() => handleModalSocial()}></div>
                            <div className="buttonGuardarPhoneCreater" onClick={() => handleCrearGreen()}>
                                Crear GreenCard
                            </div>
                        </div>

                        <div className="textareaContainerPhoneCreate">
                            <textarea id="textTareaInput" className="textTareaInput" value={eslogan} onChange={(e) => setEslogan(e.target.value)} ></textarea>
                            <button type="button" onClick={() => handleConfirmar()} className="buttonContainerPhoneCreate">Confirmar</button>
                            <div className="coverTextareaContainerPhoneCreate"></div>
                        </div>

                        <div className="ModalSocialContainerPhoneCreate">
                            <form className="containerSocialPhoneCreater" onSubmit={(e) => handleRedesSociales(e)}>
                                <div className="ColumnaContainerSocialPhoneCreate">
                                    {redSocial.map((red, index) => {
                                        if (red.icono === 'fas fa-phone') {
                                            return (
                                                <div className="rowContainerSocialPhoneCreate">
                                                    <div onClick={() => selectIcon(index, red.id)} id={red.id} className={`circleContainerSocialPhoneCreate`}>
                                                        <i className={red.icono}></i>
                                                    </div>
                                                    <div className="inputContainerSocialPhoneCreate">
                                                        <input type="number" placeholder="Ingresa tu telefono" required />
                                                    </div>
                                                    <div onClick={() => deleteRedSocial(red.id)} className="DeleteRowContainerSocialPhoneCreate"><i className="far fa-trash-alt"></i></div>
                                                </div>
                                            )
                                        }
                                        if (red.icono === 'fab fa-whatsapp') {
                                            return (
                                                <div className="rowContainerSocialPhoneCreate">
                                                    <div onClick={() => selectIcon(index, red.id)} id={red.id} className="circleContainerSocialPhoneCreate">
                                                        <i className={red.icono}></i>
                                                    </div>
                                                    <div className="inputContainerSocialPhoneCreate">
                                                        <input type="number" placeholder="Ingresa tu whatsapp" required />
                                                    </div>
                                                    <div onClick={() => deleteRedSocial(red.id)} className="DeleteRowContainerSocialPhoneCreate"><i className="far fa-trash-alt"></i></div>
                                                </div>
                                            )
                                        }
                                        if (red.icono === 'fas fa-at') {
                                            return (
                                                <div className="rowContainerSocialPhoneCreate">
                                                    <div onClick={() => selectIcon(index, red.id)} id={red.id} className="circleContainerSocialPhoneCreate">
                                                        <i className={red.icono}></i>
                                                    </div>
                                                    <div className="inputContainerSocialPhoneCreate">
                                                        <input type="email" placeholder="Ingresa tu Email" required />
                                                    </div>
                                                    <div onClick={() => deleteRedSocial(red.id)} className="DeleteRowContainerSocialPhoneCreate"><i className="far fa-trash-alt"></i></div>
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div className="rowContainerSocialPhoneCreate">
                                                    <div onClick={() => selectIcon(index, red.id)} id={red.id} className="circleContainerSocialPhoneCreate">
                                                        <i className={red.icono}></i>
                                                    </div>
                                                    <div className="inputContainerSocialPhoneCreate">
                                                        <input type="text" placeholder="Ingresa tu link" required />
                                                    </div>
                                                    <div onClick={() => deleteRedSocial(red.id)} className="DeleteRowContainerSocialPhoneCreate"><i className="far fa-trash-alt"></i></div>
                                                </div>
                                            )
                                        }
                                    })}
                                </div>
                                {redSocial.length < 6 &&
                                    <div onClick={() => agregarLink()} className="agregarContainerSocialPhoneCreate">
                                        Agregar Link
                                    </div>
                                }
                                <button type="submit" className="guardarContainerSocialPhonrCreate">
                                    Guardar
                                </button>
                            </form>
                            <div className="iconsContainerSocialPhoneCreate">
                                {icons.map(icono => {
                                    return (
                                        <div onClick={() => handleIcon(icono)}>
                                            <i className={icono}></i>
                                        </div>
                                    )
                                })}
                                <div onClick={() => closeIcons()} className="coverIconsContianerSocialPhoneCreate"></div>
                            </div>
                            <div className="coverTextareaContainerPhoneCreate"></div>
                        </div>

                        <div className="phoneCreate">
                            <div className="coverPhoneCreate" style={{ backgroundImage: `URL('${imagen}')` }}></div>
                            <div className="degradadoPhoneCreate" style={{ background: `linear-gradient(rgba(255,255,255,0),${color})` }}></div>
                            <div className="containerInfoPhoneCreate">
                                <div className="logoSloganPhoneCreate">
                                    <div>
                                        <div className="sloganPhoneCreate">{eslogan}</div>
                                    </div>
                                    <div>
                                        <img src={`${logo}`} alt="" />
                                    </div>
                                    <div><i className="fas fa-eye"></i> 2500</div>
                                </div>
                                <div className="botoneraPhoneCreate" >
                                    {redSocial.map(red => { return (<div className={red.id}><i className={red.icono}></i></div>) })}

                                </div>
                                <div className="logoPhoneCreate">
                                    <img src="./images/greencard.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="containerUrlDisponible">
                        <div className="titleUrlDisponible">Elijamos tu Subdominio</div>
                        <div className="inputUrlDisponible"><input value={subdominio} onChange={(e) => handleSubdominio(e)} type="text" /> .greencardpv.com</div>
                        <div className="buttonGuardarPhoneCreater"
                            onClick={() => checkUrl()}
                            style={{ position: 'relative', marginBottom: '0', width: 'auto', marginTop: '2em' }}
                        >
                            Verificar Url</div>
                    </div>
                    <div className="containerPago">
                        <div className="titleUrlDisponible">Pagar</div>
                        <div id="formPayme"></div>
                    </div>
                </div>
            </div>
        )
    }

}
const PropsStore = state => {
    return {
        usuario: state.usuario.usuario
    }
}


export default connect(PropsStore, null)(Home)
